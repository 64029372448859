import { application } from "./application"

import CookieBannerController from "./cookie_banner_controller"
application.register("cookie-banner", CookieBannerController)

import MapController from "./map_controller"
application.register("map", MapController)

import NavbarController from "./navbar_controller"
application.register("navbar", NavbarController)

